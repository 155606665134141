<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main">
            <el-form :model="form" ref="form" :rules="rules" label-width="145px" label-position="left">
                <el-form-item label="物业名称" prop="name" >
                    <el-input v-model="form.name" maxlength="24" style="width:240px" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="银行账户信息" prop="account_info">
                    <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addAccountInfo">{{ form.account_info?'修改':'添加' }}</el-button>
                    <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.account_info">
                        <div class="a-flex-rfsfs a-flex-wrap">
                            <div class="a-flex-rfsfs mt21 mr80">
                                <span class="title c79">账户类型</span>
                                <span class="content a-c-master">{{ form.account_info.bank_account_type | initDic(bank_account_typeDic) }}</span>
                            </div>
                            <div class="a-flex-rfsfs mt21 mr80">
                                <span class="title c79">开户银行</span>
                                <span class="content a-c-master">{{ form.account_info.account_bank }}</span>
                            </div>
                            <div class="a-flex-rfsfs mt21 mr80">
                                <span class="title c79">开户银行别名</span>
                                <span class="content a-c-master">{{ form.account_info.bank_alias }}</span>
                            </div>
                            <div class="a-flex-rfsfs mt21 mr80">
                                <span class="title c79">开户名称</span>
                                <span class="content a-c-master">{{ form.account_info.account_name || '-' }}</span>
                            </div>
                            <div class="a-flex-rfsfs mt21 mr80">
                                <span class="title c79">开户银行省市编码</span>
                                <span class="content a-c-master">{{ form.account_info.bank_address_code || '-' }}</span>
                            </div>
                            <div class="a-flex-rfsfs mt21 mr80">
                                <span class="title c79">开户银行联行号</span>
                                <span class="content a-c-master">{{ form.account_info.bank_branch_id || '-' }}</span>
                            </div>
                            <div class="a-flex-rfsfs mt21 mr80">
                                <span class="title c79">开户银行全称（含支行）</span>
                                <span class="content a-c-master">{{ form.account_info.bank_name }}</span>
                            </div>
                            <div class="a-flex-rfsfs mt21 mr80">
                                <span class="title c79">银行账号</span>
                                <span class="content a-c-master">{{ form.account_info.account_number || '-' }}</span>
                            </div>
                        </div>
                    </el-card>
                </el-form-item>
                <el-form-item label="票据类型" prop="billType" >
                    <el-select v-model="form.billType" placeholder="请选择" clearable>
                        <el-option v-for="item in billTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="周期" prop="alertCycle" >
                    <el-select v-model="form.alertCycle" placeholder="请选择" clearable>
                        <el-option v-for="item in timeCycleDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="提醒日期" prop="alertDate" >
                    <el-select v-model="form.alertDate" placeholder="请选择" clearable>
                        <el-option v-for="(item, index) in dateDic"
                            :key="index"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="到期日期" prop="dueDate" >
                    <el-select v-model="form.dueDate" placeholder="请选择" clearable>
                        <el-option v-for="(item, index) in dateDic"
                            :key="index"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div style="height: 50px;"></div>
            </el-form>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">返回</el-button>
            </div>
        </el-card>
        <account-info ref="accountInfo" @change="accountInfoChange"></account-info>
    </div>
</template>

<script>
import { number } from 'echarts';
import AccountInfo from '../companyUpgrade/child/accountInfo.vue';
export default {
    components: {
        AccountInfo
    },
    data () {
        return {
            id: '',
            editType: '',
            form: {
                name: '',//物业名称
                account_info: '',
                billType: '',//票据类型
                alertCycle: '',//周期类型
                alertDate: '',//日期
                dueDate: ''
            },
            rules: {
                name: [{required: true, message:'请输入物业名称', trigger: 'blur'}],//物业名称
                account_info: [{required: true, message:'请添加银行账户信息', trigger: 'change'}],
                billType: [{required: true, message:'请选择票据类型', trigger: 'change'}],//票据类型
                alertCycle: [{required: true, message:'请选择周期类型', trigger: 'change'}],//周期类型
                alertDate: [{required: true, message:'请选择提醒日期', trigger: 'change'}],//日期
                dueDate: [{required: true, message:'请选择到期日期', trigger: 'change'}],//日期
            },
            bank_account_typeDic: [],
            billTypeDic: [],
            timeCycleDic: [],
            dateDic: [],
        }
    },
    mounted () {
        this.id = this.$route.query.id
        this.editType = this.$route.query.type
        if(this.id) {
            this.getPropertyDetail()
        }
        this.$getDic('bank_account_type','select').then(res=>{ this.bank_account_typeDic = res; })
        this.$getDic('timeCycle','select').then(res=>{ this.timeCycleDic = res; })
        this.$getDic('billType','select').then(res=>{ this.billTypeDic = res; })
     
        for (let index = 1; index <= 31; index++) {
            if(index<10){
                this.dateDic.push({
                    label: '0' + index + '日',
                    value: '2012-12-0' + index
                })
            }else{
                this.dateDic.push({
                    label: index + '日',
                    value: '2012-12-' + index
                })
            }
        }
    },
    methods:{
        // 查询物业信息
        getPropertyDetail () {
            this.$Axios._post({
                url: this.$Config.apiUrl.propertyCompanyInfo,
                method: "post",
                params: {
                    id: this.id
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.form = {
                        name: res.result.data.name,//物业名称
                        account_info: {
                            bank_account_type: res.result.data.bank_account_type,
                            account_bank: res.result.data.account_bank,
                            bank_alias: res.result.data.bank_alias,
                            bank_alias_code: res.result.data.bank_alias_code,
                            account_name: res.result.data.account_name,
                            bank_address_code: res.result.data.bank_address_code,
                            bank_address_code_province: res.result.data.bank_address_code_province,
                            bank_wx_address_code: res.result.data.bank_wx_address_code,
                            bank_branch_id: res.result.data.bank_branch_id,
                            bank_name: res.result.data.bank_name,
                            account_number: res.result.data.account_number
                        },

                        billType: JSON.stringify(res.result.data.billType),//票据类型
                        alertCycle: JSON.stringify(res.result.data.alertCycle),//周期类型
                        alertDate: res.result.data.alertDate,//日期
                        dueDate: res.result.data.dueDate
                    }
                    
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        //添加结算账户信息
        addAccountInfo () {
            if(this.form.account_info){
                this.$refs['accountInfo'].form = {
                    ...this.form.account_info,
                    bank_address_code_province: Number(this.form.account_info.bank_address_code_province),
                    bank_wx_address_code: Number(this.form.account_info.bank_wx_address_code),
                }
                if(this.form.account_info.bank_account_type == 75){
                    this.$refs['accountInfo'].getWXBankPersonal()
                }else{
                    this.$refs['accountInfo'].getWXBankCorporate()
                }
                this.$refs['accountInfo'].getWXBankCities()
                this.$refs['accountInfo'].getWXBankBranches()
            }
            this.$refs['accountInfo'].dialogVisible = true
        },
        accountInfoChange (datas) {
            console.log(datas);
            this.form.account_info = datas
            // this.$refs['form'].clearValidate(['account_info'])
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let reqData = {
                        ...this.form,
                        ...this.form.account_info,
                        id: this.id
                    }
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        this.$Axios._post({
                            url: this.$Config.apiUrl.propertyCompanyEdit,
                            method: "post",
                            params: reqData
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$router.back()
                                this.$store.dispatch("delView", this.$route);
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{

                        })
                    })
                    .catch(_=>{})
                }else{
                    this.$message.error('请检查所填参数是否正确')
                }
            })
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        }
    },
}
</script>

<style lang="scss" scoped>
.send-user{
    background: #53A8FF;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 10px;
    margin: 0 10px 10px 0;
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 12px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 150px;
    margin-right: 16px;
}
.content{
    width: 240px
}
.tips{
    font-size: 12px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 22px;
}
</style>